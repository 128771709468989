import React from "react"
import { format, startOfMonth, addDays, getDay, addMonths } from "date-fns"
import { Button } from "@material-ui/core"
import firebase from "../../utils/firebase"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import CircularProgress from "@material-ui/core/CircularProgress"
import Typography from "@material-ui/core/Typography"
import AddReserve from "./AddReserve"
import EventPickup from "./EventPickup"

// calendarType: 'event', 'reservation'
export default calendarType => {
  return class Calendar extends React.Component {
    constructor(props) {
      super(props)
      this.addReserveRef = React.createRef()
      this.now = new Date()
      this.reserveLimitDay = addDays(this.now, 7)
      this.limitMonth = addMonths(this.now, 3)
      const firstOfMonth = startOfMonth(new Date())
      this.state = {
        loading: true,
        openDialogEvent: false,
        firstOfMonth: firstOfMonth,
        calendarBeginningDay: addDays(firstOfMonth, -getDay(firstOfMonth)),
        salesDates: {}, // '2020/04/30' format
      }
    }
    componentDidMount() {
      this.calendarUpdate()
    }

    calendarUpdate = () => {
      this.setState({ salesDates: {}, loading: true }, this.fetchCalendarEvents)
    }

    getEventType = userType => {
      if (calendarType === "reservation") return "[予約可]"
      return {
        "1": "[フリー]", // フリーメンバー
        "2": "[フリー]|[有料]", // 有料メンバー
        "3": "[フリー]|[有料]|[コア]", // コアメンバー
      }[userType.toString()]
    }

    fetchCalendarEvents = () => {
      const functions = firebase.functions()
      functions
        .httpsCallable("getCalendars")({
          year: format(this.state.firstOfMonth, "yyyy"),
          month: format(this.state.firstOfMonth, "MM"),
          filter: this.getEventType(this.props.user.userType),
        })
        .then(res => {
          var salesDates = {}
          for (const event of res.data) {
            let a_date = event.start.date
            if(!a_date) a_date = event.start.dateTime.split('T')[0]
            let dstr = a_date.replace(/-/g, "/")
            if (!salesDates[dstr]) {
              salesDates[dstr] = []
            }
            salesDates[dstr].push(event)
          }
          this.setState({ salesDates: salesDates, loading: false })
        })
        .catch(e => {
          console.log(e)
        })
    }

    handleNextMonth = () => {
      var firstOfMonth = startOfMonth(addMonths(this.state.firstOfMonth, 1))
      this.setState(
        {
          firstOfMonth: firstOfMonth,
          calendarBeginningDay: addDays(firstOfMonth, -getDay(firstOfMonth)),
        },
        this.calendarUpdate
      )
    }
    handlePrevMonth = () => {
      var firstOfMonth = startOfMonth(addMonths(this.state.firstOfMonth, -1))
      this.setState(
        {
          firstOfMonth: firstOfMonth,
          calendarBeginningDay: addDays(firstOfMonth, -getDay(firstOfMonth)),
        },
        this.calendarUpdate
      )
    }
    handleCloseDialogEvent = e => {
      e.preventDefault()
      this.setState({
        openDialogEvent: false,
      })
    }
    handleCloseDialogReserve = e => {
      e.preventDefault()
      this.setState({
        openDialogReserve: false,
      })
    }
    select_date = (e, selectday, event) => {
      e.preventDefault()
      var dayArray = selectday.split("-")
      var selectDayObj = new Date(
        dayArray[0],
        parseInt(dayArray[1]) - 1,
        dayArray[2]
      )
      if (calendarType === "reservation") {
        this.setState({
          openDialogReserve: (
            <AddReserve
              ref={this.addReserveRef}
              targetDay={selectDayObj}
              event={event}
              user={this.props.user}
            />
          ),
        })
      } else {
        this.setState({
          openDialogEvent: (
            <div>
              <Typography variant="h5" gutterBottom>
                {format(selectDayObj, "yyyy年MM月dd日")}
              </Typography>
              <ul>
                {(() => {
                  return this.state.salesDates[
                    format(selectDayObj, "yyyy/MM/dd")
                  ].map((event, i) => {
                    return (
                      <Typography variant="h6" key={i}>
                        {event.summary}
                      </Typography>
                    )
                  })
                })()}
              </ul>
            </div>
          ),
        })
      }
    }

    handleAddReserve = e => {
      e.preventDefault()
      this.setState(
        { loading: true },
        this.addReserveRef.current.handleAddReserve(() => {
          this.setState({
            loading: false,
            openDialogEvent: (
              <div>
                受け付けました。登録メールアドレスに確認メールを送信しています。
              </div>
            ),
            openDialogReserve: false,
          })
        })
      )
    }

    render() {
      //const lang = this.props.lang
      return (
        <React.Fragment>
          <Dialog open={this.state.loading}>
            <DialogContent>
              <CircularProgress />
            </DialogContent>
          </Dialog>
          <Dialog open={!!this.state.openDialogEvent}>
            <DialogContent>{this.state.openDialogEvent}</DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseDialogEvent} color="primary">
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={!!this.state.openDialogReserve}>
            <DialogTitle>予約申し込み</DialogTitle>
            <DialogContent>{this.state.openDialogReserve}</DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseDialogReserve} color="primary">
                キャンセル
              </Button>
              <Button onClick={this.handleAddReserve} color="primary">
                予約
              </Button>
            </DialogActions>
          </Dialog>
          <div className="calendar">
            <Typography variant="h5" gutterBottom>
              {(() => {
                if (calendarType === "event") return "イベント"
                if (calendarType === "reservation") return "施設利用"
              })()}
            </Typography>
            <Typography variant="p" gutterBottom>
              {(() => {
                if (calendarType === "event") return ""
                if (calendarType === "reservation")
                  return (
                    <>
                      箕澤屋の建物を個人でご利用希望の方は、下記のカレンダーから空き状況をチェックして、予約希望をお送りください。なお、箕澤屋のご利用を希望される方は、事前に
                      「箕澤屋施設ご利用のルール」
                      を確認し、ご理解いただいたうえでご予約をお願いいたします。
                    </>
                  )
              })()}
            </Typography>

            {(() => {
              if (calendarType === "event") return <EventPickup />
            })()}

            <div>
              <div className="calendar_wrapper">
                <div className="this_month">
                  <div style={{ fontWeight: "bold" }}>
                    {format(this.state.firstOfMonth, "yyyy年MM月")}
                  </div>
                  <div className="font_eighty">
                    {format(this.state.firstOfMonth, "MMMM")}
                  </div>
                </div>

                <div className="prev_calendar">
                  {(() => {
                    if (
                      parseInt(format(this.state.firstOfMonth, "yyyyMM")) >
                      parseInt(format(this.now, "yyyyMM"))
                    ) {
                      return (
                        <Button
                          className="button"
                          onClick={this.handlePrevMonth}
                        >
                          <span>
                            <span>前月 / </span>
                            <span className="font_eighty">Previous Month</span>
                          </span>
                        </Button>
                      )
                    }
                  })()}
                </div>

                <div className="next_calendar">
                  {(() => {
                    if (
                      parseInt(format(this.state.firstOfMonth, "yyyyMM")) <
                      parseInt(format(this.limitMonth, "yyyyMM"))
                    ) {
                      return (
                        <Button
                          className="button"
                          onClick={this.handleNextMonth}
                        >
                          <span>
                            <span>次月 / </span>
                            <span className="font_eighty">Next Month</span>
                          </span>
                        </Button>
                      )
                    }
                  })()}
                </div>
                <div className="clear"></div>
                <div className="calendar_table">
                  <div className="calendar_tr">
                    <div className="calendar_th">
                      <div>日 Sun</div>
                    </div>
                    <div className="calendar_th">
                      <div>月 Mon</div>
                    </div>
                    <div className="calendar_th">
                      <div>火 Tue</div>
                    </div>
                    <div className="calendar_th">
                      <div>水 Wed</div>
                    </div>
                    <div className="calendar_th">
                      <div>木 Thu</div>
                    </div>
                    <div className="calendar_th">
                      <div>金 Fri</div>
                    </div>
                    <div className="calendar_th">
                      <div>土 Sat</div>
                    </div>
                  </div>
                  {(() => {
                    let day = this.state.calendarBeginningDay
                    var weeks = []
                    for (var i = 0; i <= 5; i++) {
                      if (
                        format(day, "yyyyMM") <=
                        format(this.state.firstOfMonth, "yyyyMM")
                      ) {
                        let weekDays = []
                        weeks.push(
                          <div key={i} className="calendar_tr">
                            {((arg_day, arg_i) => {
                              let week_day = arg_day
                              let days = []
                              for (var j = 0; j < 7; j++) {
                                let dateString = format(week_day, "yyyy/MM/dd")
                                weekDays.push(dateString)
                                var classnames = ["calendar_td"]
                                if (j === 0) {
                                  classnames.push("sunday")
                                }
                                if (j === 6) {
                                  classnames.push("saturday")
                                }
                                let pastFlag =
                                  calendarType === "reservation" &&
                                  this.reserveLimitDay > week_day

                                if (
                                  !pastFlag &&
                                  Object.keys(this.state.salesDates).indexOf(
                                    dateString
                                  ) > -1
                                ) {
                                  classnames.push("cursor")
                                  classnames.push("hover_color")
                                  if (
                                    format(week_day, "yyyyMM") !==
                                    format(this.state.firstOfMonth, "yyyyMM")
                                  ) {
                                    classnames.push("not_same_month")
                                  }
                                  days.push(
                                    <div
                                      key={`${arg_i}-${j}`}
                                      role="link"
                                      tabIndex={0}
                                      className={classnames.join(" ")}
                                      onClick={((selectday, events) => {
                                        return e => {
                                          this.select_date(
                                            e,
                                            selectday,
                                            events[0]
                                          )
                                        }
                                      })(
                                        format(week_day, "yyyy-MM-dd"),
                                        this.state.salesDates[dateString]
                                      )}
                                      onKeyDown={e => {
                                        e.preventDefault()
                                      }}
                                    >
                                      {format(week_day, "dd")}
                                    </div>
                                  )
                                } else {
                                  classnames.push("past")
                                  days.push(
                                    <div
                                      key={`${arg_i}-${j}`}
                                      className={classnames.join(" ")}
                                    >
                                      {format(week_day, "dd")}
                                    </div>
                                  )
                                }
                                week_day = addDays(week_day, 1)
                              }
                              return days
                            })(day, i)}
                          </div>
                        )
                        day = addDays(day, 7)
                      }
                    }
                    return weeks
                  })()}
                </div>
              </div>
            </div>
            <div className="bottom"></div>
          </div>
        </React.Fragment>
      )
    }
  }
}
