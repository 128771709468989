import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  paper: {
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
    margin: "2rem 0",
    padding: "2rem",
  },
  heading: {
    fontSize: "1rem",
    marginBottom: "1rem",
  },
  name: {
    fontSize: "0.9rem",
    marginTop: "0.5rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.8rem",
    },
  },
}))

export default () => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query {
      eventImage1: file(relativePath: { eq: "member/event/event1.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 300) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      eventImage2: file(relativePath: { eq: "member/event/event2.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 300) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      eventImage3: file(relativePath: { eq: "member/event/event3.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 300) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      eventImage4: file(relativePath: { eq: "member/event/event4.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 300) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography className={classes.heading}>
          気は早いけれど、2021年のイベント計画中！
        </Typography>
        <Grid
          container
          spacing="3"
          justify="space-between"
          alignItems="flex-start"
        >
          <Grid item xs={6} md={3}>
            <Img fluid={data.eventImage1.childImageSharp.fluid} alt="" />
            <Typography className={classes.name} align="center">
              春の大掃除（5月）
            </Typography>
          </Grid>
          <Grid item xs={6} md={3}>
            <Img fluid={data.eventImage2.childImageSharp.fluid} alt="" />
            <Typography className={classes.name} align="center">
              サポーター釣り＆BBQ大会（6月）
            </Typography>
          </Grid>
          <Grid item xs={6} md={3}>
            <Img fluid={data.eventImage3.childImageSharp.fluid} alt="" />
            <Typography className={classes.name} align="center">
              箕澤屋の日（7月）
            </Typography>
          </Grid>
          <Grid item xs={6} md={3}>
            <Img fluid={data.eventImage4.childImageSharp.fluid} alt="" />
            <Typography className={classes.name} align="center">
              箕澤屋かき氷カフェ（8月）
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}
