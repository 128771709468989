import React from "react"
import { format } from 'date-fns'
import styles from "../../assets/scss/member/profile.module.scss"
import TextField from "@material-ui/core/TextField"
import firebase from "../../utils/firebase"

export default class AddReserve extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      usage: '',
      errorUsage: '',
    }
  }

  setUsage = (value) => {
    this.setState({usage: value})
  }

  setErrorUsage = (value) => {
    this.setState({errorUsage: value})
  }

  validateUsage = () => {
    if( this.state.usage === '' ){
      this.setErrorUsage('入力してください。')
      return false
    }
    this.setErrorUsage('')
    return true
  }

  handleAddReserve = (callback) => {
    ( async () => {
      let resValidateUsage = this.validateUsage()
      if( resValidateUsage ){
        const functions = firebase.functions();
        await functions.httpsCallable("addReserve")({
          uid: this.props.user.uid,
          reserveDate: format(this.props.targetDay, 'yyy-MM-dd'),
          calendarId: this.props.event.id,
          usage: this.state.usage,
        })
      }
      callback()
    } )()
  }

  render() {
    return (<React.Fragment>
      <div>
        下記情報をご確認の上、「予約」ボタンで申し込みください。<br />
        日程調整後、再度こちらからご連絡いたします。
      </div>
      <div>
        申込日: { format(this.props.targetDay, 'yyyy年MM月dd日') }
      </div>
      <div>
        申込者: { this.props.user.lastName } { this.props.user.firstName }
      </div>
      <div>
        メールアドレス: { this.props.user.email }
      </div>
      <div>
        電話番号: { this.props.user.phone }
      </div>
      <div className={styles.elem}>
        <TextField
          label="利用用途、要望等"
          value={this.state.usage}
          multiline
          rows={4}
          error={ this.state.errorUsage !== '' }
          helperText={ this.state.errorUsage }
          onBlur={ this.validateUsage }
          onChange={ (e) => { this.setUsage(e.target.value);  } }
          autoComplete='off'
        />
      </div>
    </React.Fragment>)
  }
}